.single-post
	.content, #share
		max-width: 800px
		margin-left: auto
		margin-right: auto
		@media only screen and (max-width: $lg)
				max-width: calc( 100% -  50px )
	.img
		margin-left: auto
		margin-right: auto
		padding-left: 20px
		padding-right: 20px
		.lazy-wrapper
			margin: 0 auto	
			display: block
		p
			padding-top: 20px
			max-width: 600px
			margin: 0 auto

	.editor
		a
			font-weight: bold
			text-decoration: underline
			color: var(--color-blue-1)	
		a:hover
			color: var(--color-blue-2) !important

			

	.publicidad
		padding-top: 30px
		padding-bottom: 30px
		.uno
			max-width: 700px
			margin: 0 auto
	.intro .of-cover, .intro .hover-zoom__inner
		min-height: 560px !important
		@media only screen and (max-width: $md)
			min-height: auto !important
		
	.intro
		.mt-medium
			@media only screen and (max-width: $xs)
				margin-top: 30px
			
		.px-small
			@media only screen and (max-width: $xl)
				padding: 0px 10px !important
			@media only screen and (max-width: $md)
				padding: 0px !important	

	.section-slider-images.vertical
		max-width: 550px
		.slider__scrollbar_horizontal
			max-width: 800px
	.section-slider-images.horizontal
		max-width: 1200px	
	.slider__wrapper-scrollbar	
		::before
			display: none


#share
	a.heateor_sss_whatsapp
		span
			width: 35px !important	
			height: 35px !important
			position: relative
			top: -2px
			left: -1px
	a.heateor_sss_button_linkedin
		span
			position: relative
			left: 2px


.gallery-intro	
	.desc
		position: absolute
		bottom: 0
		z-index: 99
		background: transparent linear-gradient(180deg, #00215D00 0%, #00215D79 30%, #00215DEC 68%, #00215D 100%) 0% 0% no-repeat padding-box
		width: 100%
		padding: 10px 10px 0px 10px
		.small
			color:#fff
			text-align: left
	.slider__arrow
		background: #fff
		border-radius: 50%	
		width: 30px
		height: 30px
		&.slider__arrow-next
			transform: translateX(-10px) translateY(-50%)
			position: absolute
			top: 50%
			right: 0
		&.slider__arrow-prev
			transform: translateX(10px) translateY(-50%)	
			position: absolute
			top: 50%
		
.patrocinado
	@media only screen and (max-width: $xs)
		margin-bottom: 20px
	img
		max-width: 200px
