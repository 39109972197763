#page-header
	background: #fff
	box-shadow: 0px 0px 30px 0px rgba(24, 24, 24, 0.09)
	.logo
		img
			@media only screen and (max-width: $lg)
				width: 200px
			@media only screen and (max-width: $xs)
				width: 180px	
	.menu
		margin: 0px !important
		.menu-item
			a
				font-weight: normal
				text-transform: uppercase
				font-size: 15px

	.logo-container
		padding-bottom: 10px
		@media only screen and (max-width: $xs)
			padding-bottom: 15px
	&.opened
		.menu-container
			&::before
				opacity: 1

	.menu-container
		position: relative
		&::before
			content: ''
			width: calc( 100% - 30px)
			height: 1.5px
			background: rgba(#166AF2, 0.5)
			position: absolute
			top: 0
			left: 0
			right: 0
			margin: 0 auto
			@media only screen and (max-width: $md)
				background: var(--color-blue-2)
				height: 1px
				opacity: 0

		.centro
			display: flex
			justify-content: center
			width: 100%
			span
				font-weight: bold
				padding-right: 0.4px
	.somos
		a
			margin: 0 !important
			font-weight: bold !important
			font-size: 14px !important
		.newsletter
			background: var(--color-blue-1)
			&:hover
				background: rgba(#166AF2, 0.8)
			a
				color: #fff !important
	.current-menu-item .no-hover, .no-hover:hover
		background-size: 0 !important
		span
			color: var(--color-gray-1) !important

.header_sticky
	padding-top: 8px !important
	.logo-container
		padding-bottom: 8px !important

	.logo__wrapper-img
		img
			width: 180px
	.menu-item a
		font-size: 15px !important
		padding: 0.7em 1em !important

.pt-header
	padding-top: 190px
	@media only screen and (max-width: $xl)
		padding-top: 180px
	@media only screen and (max-width: $md)
		padding-top: 110px

.pt2-header
	padding-top: 134px
	@media only screen and (max-width: $lg)
		padding-top: 130px
	@media only screen and (max-width: $md)
		padding-top: 70px


.pt3-header
	padding-top: 170px
	@media only screen and (max-width: $xl)
		padding-top: 160px
	@media only screen and (max-width: $lg)
		padding-top: 110px
.punto
	font-family: "Times New Roman", Times, serif
	@include fluid('font-size', 50, 70)
	color: var(--color-blue-1)

.color-white, .color-white p
	color: #fff !important

.color-blue-1
	color: var(--color-blue-1) !important

.color-blue-2
	color: var(--color-blue-2) !important

.bg-blue-1
	background: var(--color-blue-1)

.bg-blue-2
	background: var(--color-blue-2)

.fw-bold
	font-weight: bold !important

.style_1
	em
		font-style: normal !important
		color: var(--color-blue-1)

.border-bottom
	border-bottom: 1.5px solid var(--color-gray-1) !important

.black
	color: #000 !important

.gray
	color: var(--color-gray-3) !important

.fw-500
	font-weight: 500 !important

.fw-400
	font-weight: 400 !important
.fw-300
	font-weight: 300 !important

.menu-overlay
	.style_2
		text-transform: uppercase
		font-size: 18px
		font-weight: bold
	.h3
		text-transform: uppercase
		color: var(--color-blue-2)
	.border-top
		border-top: 1px solid var(--color-blue-2) !important
		width: calc( 100% - 20px )

.header__wrapper-overlay-menu
	.header__menu-column
		&::after
			content: ''
			width: 100%
			position: absolute
			left: 0
			height: 62px
			background: #fff
			top: 0

.mailchimp
	margin-top: 10px
	.mc4wp-form-fields
		width: 360px
		line-height: 20px
	label
		height: 30px
		width: calc( 100% - 40px)
		padding-right: 10px
		line-height: 30px
		input
			width: 100%
			border: none
			font-size: 14px
			color: #fff
			background: #000
			border-bottom: 1px solid #fff
			text-transform: uppercase
			opacity: 1
			::placeholder
				color: #fff

	.btn
		height: 30px
		width: 30px
		line-height: 40px
		background: url('../img/flecha_der_negra.png'), #fff
		background-size: 20px 14px
		background-repeat: no-repeat
		background-position: center center
		border: none
		border-radius: 50%
		position: relative
		top: 10px

.cat
	background: var(--color-blue-1)
	border: 1px solid #fff
	padding: 4px 6px
	font-size: 13px !important
	font-weight: normal !important
	color: #fff !important
	&:hover
		background: var(--color-blue-2)
		color: #fff !important

	span
		font-weight: bold
		padding-right: 0.4px

.autor
	font-weight: normal
	span
		font-weight: normal

.date
	font-size: 14px
	font-weight: bold
	color: var(--color-gray-2)


.hover-img1
	a
		position: relative
		display: block
	&:hover 
		.lazy::after
			content: ""
			position: absolute
			height: 100%
			width: 100%
			background: var(--color-blue-1)
			z-index: 2
			display: block
			top: 0
			opacity: 0.6

			

.scroll
	position: absolute
	top: 50px

.scroll_2
	position: absolute
	top: -30px	
			
#page-header .somos .menu-item-has-children a
		font-size: 13px !important	
		padding: 0.8em 1em !important
