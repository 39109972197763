.section_h-600
	p
		margin: 0px

	@media only screen and (max-width: $xl)
		height: 560px !important
	@media only screen and (max-width: $lg)
		height: 500px !important
	.ico
		@media only screen and (max-width: $xl)
			width: 120px
			height: 120px
		@media only screen and (max-width: $lg)
			width: 100px
			height: 100px
		@media only screen and (max-width: $sm)
			width: 80px
			height: 80px

.fix-equipo
	.col
		padding: 0px 50px 60px
		@media only screen and (max-width: $xl)
			padding: 0px 60px 60px
		@media only screen and (max-width: $xs)
			padding: 0px 30px 50px

.fix-equipo-2
	.container
		@media only screen and (max-width: $md)
			max-width: 600px !important
		@media only screen and (max-width: $sm)
			max-width: 400px !important	
		@media only screen and (max-width: $xs)
			max-width: 360px !important		

	.underline-hover
		padding: 0px 50px 40px
		@media only screen and (max-width: $md)
			padding: 0px 30px 40px
		@media only screen and (max-width: $sm)
			padding: 0px 60px 50px	
