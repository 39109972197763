#buscador
	.search-form
		display: flex
		@media only screen and (max-width: $lg)
			width: 100%
		
	
		.search-field
			border: none
			border-bottom: 2px solid #fff
			background: transparent
			width: 600px
			padding: 4px 0px
			font-size: 18px
			color: #fff
			@media only screen and (max-width: $lg)
				width: 100%
		label
			@media only screen and (max-width: $lg)
				width: 100%

				
		::placeholder
			font-size: 26px
			text-transform: uppercase
			font-weight: normal
			color: #fff
	
			letter-spacing: 1px
			@media only screen and (max-width: $lg)
				font-size: 20px
		.search-submit
			border: 1px solid #fff
			color: #fff
			background: transparent
			padding-left: 14px
			padding-right: 14px
			margin-left: 20px
			font-weight: 500
			font-size: 18px
			text-transform: uppercase
			transition: all 0.2s ease-in-out
			font-weight: normal
			letter-spacing: 1px
			@media only screen and (max-width: $md)
				padding-left: 10px
				padding-right: 10px
				font-size: 14px	
			@media only screen and (max-width: $xs)
				font-size: 13px		
				margin-left: 10px	
				padding-left: 6px
				padding-right: 6px
				
				
			&:hover
				background: #fff
				color: #000



input:focus, textarea:focus, select:focus
	outline: none

	
