.swiperFooter
	overflow: hidden
	margin: 0 auto
	&::before
		content: ""
		position: absolute
		height: 100%
		width: 2px
		left: 0px
		z-index: 99
		background: #fff
	.swiper-wrapper
		margin-bottom: 60px
	.swiper-slide
		//border-left: 1px solid #909090
		img
			padding: 0px 30px
			@media only screen and (max-width: $xl)
				padding: 0px 20px
			@media only screen and (max-width: $xs)
				padding: 0px 10px


	.swiper-pagination
		bottom: 0px
		.swiper-pagination-bullet
			background: #909090

.swiperRelacionadas
	.cat-absolute
		display: none
	.swiper-wrapper
		margin-bottom: 40px
	.swiper-pagination
		bottom: -30px

.swiperHome
	.swiper-pagination
		bottom: 0px
		.swiper-pagination-bullet
			background: #fff
	.swiper-slide
		width: 380px
		@media only screen and (max-width: $xs)
			width: 340px
