.button
	position: relative
	display: inline-block
	font-size: 14px
	line-height: 1
	font-weight: 500
	white-space: nowrap
	cursor: pointer
	box-shadow: none
	padding:0px
	color: #000
	background: #fff
	text-transform: uppercase
	font-weight: bold
	@media only screen and (max-width: $md)
		font-size: 13px
	@media only screen and (max-width: $xs)
		font-size: 12px	
	+trans1
	transition-property: color, background-color, border-color
	&.bg-blue
		background: var(--color-blue-1)
		color: #fff
		border-color: var(--color-blue-1) 
	&:focus
		outline: none
	&:hover
		.button__label-normal
			.button__title
				transform: translate(0%, -100%)
				opacity: 0
			.button__icon
				opacity: 0
				transform: translate(100%, 0%)
		.button__label-hover
			.button__title
				transform: translate(0%, 0%)
				opacity: 1
			.button__icon
				opacity: 1
				transform: translate(0%, 0%)

.button_icon
	display: inline-flex
	border: none
	.button__label
		padding: 0
.button__label-hover
	position: absolute
	top: 50%
	left: 50%
	transform: translate(-50%, -50%)
	.button__title
		transform: translate(0%, 100%)
		opacity: 0
	.button__icon
		transform: translate(-100%, 0%)
		opacity: 0

.button__label-normal
	.button__title
		transform: translate(0%, 0%)
		opacity: 1

.button__title
	display: inline-block
	transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out
.button__label
	display: block
	padding: 10px 20px

.button__icon
	display: inline-flex
	align-items: center
	justify-content: center
	// width: 60px
	transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out
	font-size: 75%
.button__icon_before
	margin-right: 0.5em
.button__icon_after
	margin-left: 0.25em

.button_fullwidth
	width: 100%
.button-fullwidth
	.button
		width: 100%



