
#top-bar
	position: relative
	background-color: var(--color-blue-1)
	z-index: 9
	&.link
		+trans1
		&:hover
			background: var(--color-blue-2)
			+trans1

	p
		color: #fff
		font-weight: 300
		font-size: 15px
		line-height: 1
		margin:0px
		position: relative
		@media only screen and (max-width: $lg)
			font-size: 14px
		strong
			font-weight: bold

		span
			font-size: 16px
			position: relative
			line-height: 1
			padding: 0px 10px
			top: 1px
			

	.wrapper
		max-width: 100%
		overflow: hidden
		position: relative
		top: 0px

		.marquee
			white-space: nowrap
			overflow: hidden
			display: inline-block
			animation: marquee 20s linear infinite

			p
				display: inline-block

		@keyframes marquee
			0%
				transform: translate3d(0, 0, 0)

			100%
				transform: translate3d(-50%, 0, 0)
