.slider-projects-fullscreen
	position: relative
	width: 100%
	height: 100%
	display: flex
	align-items: center
	justify-content: center
.slider-projects-fullscreen__images
	position: absolute
	top: 0
	left: 0
	right: 0
	bottom: 0
	&[data-drag-class]
		.slider-projects-fullscreen__slide
			+trans3
		img
			+trans3
.slider-projects-fullscreen__content
	// overflow: initial
	max-width: 100vw
	max-height: 100vh
	z-index: 60
	.swiper-slide
		// opacity: 1 !important
		// z-index: 50
		// *
		// 	z-index: 50
	.swiper-slide:not(.swiper-slide-active)
		// pointer-events: none !important
		// z-index: 1
		// *
		// 	pointer-events: none !important
		// 	z-index: 1
.section-fullheight__inner_mobile
	.slider-projects-fullscreen__content
		max-height: calc(var(--fix-bar-vh, 1vh) * 100)
.slider-projects-fullscreen__container-bottom
	padding-top: var(--gutter-vertical)
	padding-bottom: var(--gutter-vertical)
.slider-projects-fullscreen__wrapper-thumbs
	display: flex
	position: absolute
	left: 0
	right: 0
	bottom: 0
	margin-left: auto
	margin-right: auto
	height: 136px
	width: 100%
	max-width: 1100px
	z-index: 60
	.swiper-slide
		cursor: pointer
.slider-projects-fullscreen__wrapper-button
	height: 136px
	width: 136px
	flex: 1 0 auto
.slider-projects-fullscreen__thumbs
	flex: 1 1 100%
.slider-projects-fullscreen__button
	display: inline-flex
	align-items: center
	justify-content: center
	width: 100%
	height: 100%
	border-radius: 0
	padding: 0
.slider-projects-fullscreen__col-content
	flex: 1 1 36vw
	width: 36vw
	max-width: 720px
.slider-projects-fullscreen__col-images
	flex: 2 1 64vw
	height: 100%
	width: 64vw
	max-width: 64vw
.slider-projects-fullscreen__slide
	display: flex
	align-items: center
	justify-content: center
	img
		width: auto
		height: calc(100vh - var(--gutter-horizontal) * 4)

.slider-projects-fullscreen__fluid-container
	padding-top: calc(var(--gutter-vertical) * 2)
	padding-bottom: calc(var(--gutter-vertical) * 2)
	z-index: 50
.slider-projects-fullscreen__sidebar
	position: absolute
	top: 0
	right: 0
	bottom: 0
	width: calc(var(--gutter-horizontal) * 2)
	z-index: 10
.slider-projects-fullscreen__counter
	position: absolute
	right: var(--gutter-horizontal)
	bottom: calc(var(--gutter-vertical) / 1.5)
	width: auto
	padding-left: 0
	padding-right: 0
	z-index: 60
.slider-projects-fullscreen__arrows
	position: absolute
	right: 40px
	bottom: 50px
	z-index: 60
	margin-left: -16px
	margin-bottom: -16px
	@media screen and (max-width: $md)
		right: 15px
		bottom: 40px
.slider-projects-fullscreen__arrows_left
	bottom: 0
	top: 0
	margin-bottom: auto
	margin-top: auto
.slider-projects-fullscreen__arrows_right-bottom
	bottom: calc(var(--gutter-vertical) / 1.5)
	right: var(--gutter-horizontal)
	left: auto
	top: auto
	margin-left: 0
	margin-right: -8px
.slider-projects-fullscreen__arrows_right-center
	bottom: 0
	right: var(--gutter-horizontal)
	left: auto
	top: 50%
	transform: translateY(-50%)
.slider-projects-fullscreen__scrollbar
	position: absolute
	right: var(--gutter-horizontal)
	padding-left: 0 !important
	padding-right: 0 !important
	top: 0
	bottom: 0
.slider-projects-fullscreen__wrapper-scrollbar
	z-index: 50
.slider-projects-fullscreen__images_scale-up
	.slider-projects-fullscreen__slide
		transform: scale(0.95)
	img
		transform: scale(1.05)
.slider-projects-fullscreen.hover-zoom
	.swiper-slide-active
		transform: scale(1)
		img
			transform: scale(1.05)
.slider-projects-fullscreen__wrapper-image
	display: block
	img
		transform: scale(1.1)
		transform-origin: center center
		user-select: none

@media screen and (max-width: $xxl)
	.slider-projects-fullscreen__fluid-container
		padding-top: calc(var(--gutter-vertical) * 3)
		padding-bottom: calc(var(--gutter-vertical) * 3)
@media screen and (max-width: $xl)
	.slider-projects-fullscreen__slide img
		width: 100%
		height: auto
		opacity: .2

@media screen and (max-width: $md)
	.slider-projects-fullscreen__wrapper-scrollbar_mobile-absolute
		position: absolute
		display: inline-block
		right: 0
		left: auto
	.slider-projects-fullscreen__col-images
		flex: 1 1 100%
		width: 100%
		max-width: unset
		margin-left: calc(var(--gutter-horizontal) * -1)
		margin-right: calc(var(--gutter-horizontal) * -1)
		overflow: hidden
	.slider-projects-fullscreen__sidebar
		width: 0
	.slider-projects-fullscreen__col-content
		padding-right: 0
		padding-left: 0
		padding-top: 2em
		padding-bottom: 2em
	// .slider-projects-fullscreen__images
	//   bottom: auto
	.slider__wrapper-arrows_vertical
		&.slider-projects-fullscreen__arrows_left
			width: 50px
		&.slider-projects-fullscreen__arrows_right-center
			width: 50px

	.slider-projects-fullscreen__arrows_left
		left: 0
	.slider-projects-fullscreen__arrows_right-center
		left: auto
		right: 0
	.slider-projects-fullscreen__arrows_right-bottom
		margin-left: 0
		left: auto
		right: 0
	.slider-projects-fullscreen__counter
		bottom: calc(var(--gutter-vertical) * 2)
	