.modal-open
	overflow: hidden
	.modal
		overflow-x: hidden
		overflow-y: auto

.modal
	position: fixed
	top: 0
	left: 0
	z-index: 6000
	display: none
	width: 100%
	height: 100%
	overflow: hidden
	outline: 0

.modal__message
	font-size: 18px
.modal-content__wrapper-button
	text-align: center
	.button
		min-width: 200px

.modal__close
	position: absolute
	top: 20px
	right: 20px
	cursor: pointer
	z-index: 60
	@media only screen and (max-width: $xs)
		top: 10px
		right: 10px

.modal-dialog
	position: relative
	width: auto
	margin: 0
	pointer-events: none
.modal-dialog_container
	max-width: 980px !important

.modal
	&.fade .modal-dialog
		transition: -webkit-transform 0.3s ease-out
		transition: transform 0.3s ease-out
		transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out
		-webkit-transform: translate(0, -50px)
		transform: translate(0, -50px)
	&.show .modal-dialog
		-webkit-transform: none
		transform: none

@media (prefers-reduced-motion: reduce)
	.modal.fade .modal-dialog
		transition: none

.modal-dialog-scrollable
	display: -ms-flexbox
	display: flex
	max-height: calc(100% - 1rem)
	.modal-content
		max-height: calc(100vh - 1rem)
		overflow: hidden
	.modal-header, .modal-footer
		-ms-flex-negative: 0
		flex-shrink: 0
	.modal-body
		overflow-y: auto

.modal-dialog-centered
	display: -ms-flexbox
	display: flex
	-ms-flex-align: center
	align-items: center
	min-height: calc(100% - 1rem)
	width: 100%
	&::before
		display: block
		height: calc(100vh - 1rem)
		content: ""
	&.modal-dialog-scrollable
		-ms-flex-direction: column
		flex-direction: column
		-ms-flex-pack: center
		justify-content: center
		height: 100%
		.modal-content
			max-height: none
		&::before
			content: none

.modal-content
	position: relative
	display: -ms-flexbox
	display: flex
	-ms-flex-direction: column
	flex-direction: column
	pointer-events: auto
	background-clip: padding-box
	outline: 0

.modal-backdrop
	position: fixed
	top: 0
	left: 0
	z-index: 1040
	width: 100vw
	height: 100vh
	background-color: #000
	&.fade
		opacity: 0
	&.show
		opacity: 0.7

.modal-header
	display: flex
	align-items: center
	justify-content: space-between
	padding: 1rem 1rem
	border-bottom: 1px solid #dee2e6
	border-top-left-radius: 0.3rem
	border-top-right-radius: 0.3rem
	.close
		padding: 1rem 1rem
		margin: -1rem -1rem -1rem auto

.modal-title
	margin-bottom: 0
	line-height: 1.5

.modal-body
	position: relative
	-ms-flex: 1 1 auto
	flex: 1 1 auto
	padding: 0

.modal-footer
	display: -ms-flexbox
	display: flex
	-ms-flex-align: center
	align-items: center
	-ms-flex-pack: end
	justify-content: flex-end
	padding: 1rem
	border-top: 1px solid #dee2e6
	border-bottom-right-radius: 0.3rem
	border-bottom-left-radius: 0.3rem
	>
		\:not(:first-child)
			margin-left: .25rem
		\:not(:last-child)
			margin-right: .25rem

.modal-scrollbar-measure
	position: absolute
	top: -9999px
	width: 50px
	height: 50px
	overflow: scroll

@media (min-width: 576px)
	.modal-dialog
		max-width: 100vw
		margin: 0px
	.modal-dialog-scrollable
		max-height: calc(100% - 3.5rem)
		.modal-content
			max-height: calc(100vh - 3.5rem)
		&::before
			height: calc(100vh - 3.5rem)
	.modal-sm
		max-width: 300px

@media (min-width: 992px)
	.modal-lg, .modal-xl
		max-width: 800px
	.modal__message
		font-size: 24px

@media (min-width: 1200px)
	.modal-xl
		max-width: 1140px

.modal__close
	.h5
		margin-right: 5px
		font-size: 18px
		line-height: 1
		color: #fff
		font-weight: bold
		letter-spacing: 1px
		@media only screen and (max-width: $lg)
			font-size: 17px
		@media only screen and (max-width: $xs)	
			font-size: 16px

.modal
	&#buscador
		background: rgba(#00215D, 0.9)
		.modal-content
			width: 100%
			padding: 3em
			min-height: 100vh
			@media only screen and (max-width: $lg)
				padding: 3em 2em

			@media only screen and (max-width: $xs)
				padding: 2em 1em
	&#newsletter
		background: rgba(#000, 0.6)
		.modal-content
			max-width: 1200px
			margin: 0 auto
			padding: 0px
			background: var(--color-blue-2)
			padding: 80px 40px 30px 50px
			.mailpoet_form
				display: inline-block
			.mailpoet_form .mailpoet_paragraph
				width: 320px !important
				margin-bottom: 14px !important
				padding-right: 0px
			.mailpoet_form .mailpoet_paragraph.last
				width: 140px !important
			.box
				max-width: 500px
				padding-bottom: 50px
			@media only screen and (max-width: $lg)
				padding: 90px 30px 30px 30px

			@media only screen and (max-width: $xs)
				padding: 90px 20px 30px 20px
	&.equipo
		background: rgba(#000, 0.6)
		.modal__close
			.h5
				color: var(--color-blue-2)
		.modal-content
			max-width: 960px
			margin: 0 auto
			padding: 0px
			background: var(--color-light-2)
			padding: 80px 60px
			@media only screen and (max-width: $md)
				padding: 60px 30px
			@media only screen and (max-width: $sm)	
				padding: 80px 30px 40px
			@media only screen and (max-width: $xs)	
				padding: 80px 20px 40px	
			.img
				@media only screen and (max-width: $sm)	
					max-width: 300px
					margin: 0 auto
				@media only screen and (max-width: $sm)	
					padding-left: 30px
					padding-right: 30px	

			.box
				max-width: 500px
				@media only screen and (max-width: $sm)	
					margin: 0 auto
				em
					font-style: normal	
					color: var(--color-blue-1)
				p
					@include fluid('font-size', 15, 18)
				.h4
					color: var(--color-blue-2)
					text-transform: uppercase
					@include fluid('font-size', 18, 22)
					font-weight: bold
					margin-bottom: 5px
				.h5
					color: var(--color-blue-1)
					@include fluid('font-size', 16, 20)
					text-transform: none
					margin-top:0px
		


		.img-bg
			position: absolute
			bottom: 0px
			max-width: 300px
			left: 0
			z-index: 1
			@media only screen and (max-width: $sm)	
				display: none
		.modal-body
			z-index: 2
	&#info
		background: rgba(#000, 0.6)
		.modal-content
			max-width: 1100px
			margin: 0 auto
			padding: 0px
			min-height: 600px
			background: var(--color-blue-2)
			.row 
				min-height: 600px
			p
				color:#fff !important
			.box
				padding: 70px 60px 100px 60px
				z-index: 9
				@media only screen and (max-width: $md)
					padding: 70px 40px 100px 40px
				@media only screen and (max-width: $sm)	
					padding: 80px 40px 50px 40px

			
		.img-bg
			position: absolute
			bottom: 0px
			max-width: 200px
			left: 0
			z-index: 1
			@media only screen and (max-width: $sm)	
				display: none			



