#home-slider
	.section-fullheight__inner, .swiper-slide
		height: 600px
		min-height: 600px
		@media screen and (max-width: $xxl)
			height: 580px
			min-height: 580px
		@media screen and (max-width: $xl)
			height: 560px
			min-height: 560px
		@media screen and (max-width: $lg)
			min-height: auto
		@media screen and (max-width: $md)
	.slider__arrow
		color: #fff 
		background: var(--color-blue-1)
		border-radius: 50%		
	.background-hover__image
		top: 0px			



		
	.box
		background: rgba(#00215D, 0.75)	
		padding: 50px	
		max-width: 780px
		padding-left: calc( var(--gutter-horizontal) * 3  )
		padding-right: calc( var(--gutter-horizontal) * 3  )
		position: relative
		@media only screen and (max-width: $md)
			max-width: calc( 100% -  100px)
			padding: 50px 30px 30px 30px
		@media only screen and (max-width: $xs)	
			max-width: 100%
			width: 100%
			padding: 40px 40px 30px 20px
		.cat:first-child
			display: block !important
		.cat
			position: absolute
			top: -60px
			left: -20px
			display: none
			@media only screen and (max-width: $md)
				left: 0px
				top: -50px
	
	
	





.home .section-post
	.col-box
		margin-bottom: 50px
		@media only screen and (max-width: $xl)
			margin-bottom: 40px	
		@media only screen and (max-width: $xs)
			margin-bottom: 30px	
			



.section-newsletter
	.pb-xlarge
		@media only screen and (max-width: $md)
			padding-bottom: 50px
