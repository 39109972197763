.footer
	color: $color-dark-1
	z-index: 100
	.contant
		a, p
			color: #fff
			font-size: 14px
	.copy
		a, p, small
			color: #fff
			font-size: 13px
		.p-fix	
			padding-left: 50px

.newsletter
	margin-bottom: -70px
	@media only screen and (max-width: $xl)
		margin-bottom: 0px
	
	.img
		position: relative
		top: -70px
		z-index: 99
		@media only screen and (max-width: $xl)
			top: 0
	p
		max-width: 400px
	.py-medium
		@media only screen and (max-width: $xxl)
			position: relative
			top: -20px
		@media only screen and (max-width: $xl)
			top: 0px
	.pr-small
		@media only screen and (max-width: $xl)
			padding-right: 15px



.footer__divider
	display: block
	position: absolute
	top: 0
	left: calc(var(--bs-gutter-x) / 2)
	right: calc(var(--bs-gutter-x) / 2)
	height: 1px
	padding-left: 0
	padding-right: 0
	width: auto
	background-color: rgba(#fff, 0.6)

.mailpoet_form
	margin-top: 20px
	padding: 0px !important
	display: flex
	flex-flow: row wrap
	.mailpoet_paragraph
		width: 50%
		padding-right: 50px
		@media only screen and (max-width: $xs)
			width: 100%
			padding-right: 20px
		&.last
			width: auto !important
	input
		background: transparent
		border: 1px solid var(--color-blue-1)
		border-radius: 30px
		color: #fff
		font-size: 14px
		padding: 7px !important
		font-weight: bold

	::placeholder
		color: #fff
		font-size: 14px
		padding: 7px !important
		text-transform: uppercase
		font-weight: bold
	.mailpoet_submit
		background: var(--color-blue-1)
		color: #fff
		text-transform: uppercase
		font-weight: bold
		font-size: 15px
		border-radius: 0px !important
		margin-top: 20px !important

.mailpoet_form.mailpoet_form_successfully_send .mailpoet_validate_success
	left: 0px !important
	top: 0px !important
	transform: none !important
	font-size: 20px !important
	text-align: left !important
	font-weight: bold
	line-height: 1.4
	text-decoration: underline
