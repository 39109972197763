$em-base: 16px
$line-height: 1.8

// Transitions
@mixin trans1($property: all)
	transition: $property 0.2s ease-in-out
@mixin trans2($property: all)
	transition: $property 0.6s cubic-bezier(0.785, 0.135, 0.150, 0.860)
@mixin trans3($property: transform)
	transition: $property 0.6s ease
@mixin reset-ul
	list-style-type: none
	padding: 0
	margin: 0

// Dropcap
@mixin dropcap
	float: left
	@include fluid-type(var(--dropcap-min-font-size), var(--dropcap-max-font-size))
	color: var(--dropcap-color-dark)
	line-height: 0.7
	font-style: normal
	margin: 0.15em 0.25em 0 0
	font-weight: 100
	&:after
		content: ''
		display: table
		clear: both

@mixin hoverZoomEffect
	// &.figure-project
	// 	.hover-zoom__zoom
	// 		transform: scale(1.05)
	.hover-zoom__zoom
		transform: scale(1)
	.hover-zoom__inner
		transform: scale(1.05)
	// .section-image__caption-horizontal, .hover-zoom__caption
	// 	transform: translate(0, -15px)
	// 	transition-delay: 0.06s
	// 	&.text-left
	// 		transform: translate(15px, -15px)
	// 	&.text-end
	// 		transform: translate(-15px, -15px)
	// .section-image__caption-horizontal_top
	// 	transform: translate(0, 15px)
	// 	transition-delay: 0.06s
	// 	&.text-left
	// 		transform: translate(15px, 15px)
	// 	&.text-end
	// 		transform: translate(-15px, 15px)

@mixin hoverUnderlineEffect
	.underline-hover__target
		background-position: 0% 100% !important
		background-size: 100% 2px !important
		.arts-split-text__line
			background-position: 0% 100% !important
			background-size: 100% 2px !important

// Responsive Breakpoints
$xxl: 1400px
$xl: 1230px
$lg: 1070px
$md: 991px
$sm: 767px
$xs: 576px

// Default min/max screen width (for fluid mixins)
$min_width: 320px
$max_width: 1920px

$distance-max-xsmall: var(--distance-max-xsmall)
$distance-max-small: var(--distance-max-small)
$distance-max-medium: var(--distance-max-medium)
$distance-max-large: var(--distance-max-large)
$distance-max-xlarge: var(--distance-max-xlarge)
$distance-min-xsmall: var(--distance-min-xsmall)
$distance-min-small: var(--distance-min-small)
$distance-min-medium: var(--distance-min-medium)
$distance-min-large: var(--distance-min-large)
$distance-min-xlarge: var(--distance-min-xlarge)
