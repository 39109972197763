$color-dark-1: var(--color-dark-1)
$color-dark-2: var(--color-dark-2)
$color-dark-3: var(--color-dark-3)
$color-dark-4: var(--color-dark-4)

$color-light-1: var(--color-light-1)
$color-light-2: var(--color-light-2)
$color-light-3: var(--color-light-3)
$color-light-4: var(--color-light-4)

$color-gray-1: var(--color-gray-1)
$color-gray-2: var(--color-gray-2)
$color-gray-3: var(--color-gray-3)
$color-gray-4: var(--color-gray-4)

$color-border-dark: var(--color-border-dark)
$color-border-light: var(--color-border-light)

$color-portfolio-1: var(--color-portfolio-1)
$color-portfolio-2: var(--color-portfolio-2)
$color-portfolio-3: var(--color-portfolio-3)
$color-portfolio-4: var(--color-portfolio-4)
$color-portfolio-5: var(--color-portfolio-5)
$color-portfolio-6: var(--color-portfolio-6)
$color-portfolio-7: var(--color-portfolio-7)
$color-portfolio-8: var(--color-portfolio-8)
$color-portfolio-9: var(--color-portfolio-9)
$color-portfolio-10: var(--color-portfolio-10)
$color-portfolio-11: var(--color-portfolio-11)
$color-portfolio-12: var(--color-portfolio-12)
