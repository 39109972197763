.background-hover
	position: relative
	display: block
	overflow: hidden
.background-hover__image
	position: absolute
	top: 10px
	left: 0
	width: 100%
	height: 100%
	overflow: hidden
	img
		+trans1
.background-hover__content
	position: relative
	z-index: 50
.background-hover__overlay
	z-index: 10 !important

.swiperHome, .swiperRelacionadas
	p
		display: none !important
.section-post
	.date
		display: none
	.con-p.sin-p
		p
			display: block
	.sin-p, .swiper-slide
		p
			display: none
			@media only screen and (max-width: $sm)
				display: block
	.con-p
		p
			@media only screen and (max-width: $xxl)
				display: none
			@media only screen and (max-width: $md)
				display: block

	.col-box
		height: 500px !important
		margin-bottom: 30px
		@media only screen and (max-width: $xs)
			height: 440px !important
		&:hover
			.background-hover__image
				img
					transform: scale(1.1)
					+trans1
	.box
		width: 80%
		background: rgba(#00215D, 0.75)
		padding: 15px 20px 20px 20px
		@media only screen and (max-width: $xs)
			width: 100%
		p
			line-height: 1.3 !important
		.autor
			margin-top: 70px
			margin-bottom: 5px
			font-size: 13px
			@media only screen and (max-width: $xs)
				margin-top: 30px
				font-size: 12px
		.date
			@media only screen and (max-width: $xs)
				font-size: 12px

.category-wrapper
	margin-top: 30px
	ul
		list-style: none
		li
			display: inline-block
			padding: 0px 20px
			&.current-cat
				a
					background: var(--color-blue-1)
					color: #fff

			a
				border: 2px solid var(--color-blue-1)
				color: var(--color-blue-1)
				padding: 4px 14px
				text-transform: uppercase
				font-weight: bold

.cat-absolute
	top: 0px
	z-index: 999
	position: absolute
	left: 0
	a
		display: none
	a:first-child
		display: block

body.category
	.cat-absolute
		display: none
	.col-box:not(.col-xl-4)
		width: 25%
		@media only screen and (max-width: $xxl)
			width: 33% !important
		@media only screen and (max-width: $xl)
			width: 50% !important
		@media only screen and (max-width: $sm)
			width: 100% !important

.multimedia
	position: absolute
	right: 20px
	top: 30px
	z-index: 999

.box-2
	padding-right: 50px
	margin-bottom: 30px
	&::before
		content: ""
		width: calc(100% - 50px)
		height: 1.5px
		background: rgba(22, 106, 242, 0.5)
		position: absolute
		top: 10px
		left: 0
		right: 0
		margin: 0 auto
	.multimedia
		top: -5px
		right: 22px	
	.cat-absolute
		> div
			display: none
		> div:first-child
			display: block !important
	.title
		margin-top: 40px		
	h4
		@include fluid('font-size', 20, 24)
		line-height: 1.2
		margin-top: 40px
		margin-bottom: 40px
	.autor
		font-size: 13px !important	
		color: var(--color-blue-1)
		margin-top: 30px



.opinion
	background: #fff
	display: inline	
	text-transform: uppercase
	padding: 2px 6px
	font-size: 13px
	font-weight: 600